<template>
    <nuxt-link
        to="/"
        class="hero-logo"
    >
        <component :is="logo" />
    </nuxt-link>
</template>

<script>
export default {
    computed: {
        logo() {
            return require('~/assets/img/logos/hth-desktop.svg?inline');
        }
    }
};
</script>

<style lang="less">
    .hero-logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        top: 4rem;

        @media @q-md-min {
            top: 6rem;
        }

        svg {
            height: 2rem;
            color: var(--color-lightest);

            @media @q-md-min {
                height: 3rem;
            }
        }
    }

    .hero-logo + [class^='hero-'] {
        padding-top: 8rem;

        @media @q-md-min {
            padding-top: 12rem;
        }
    }
</style>
