<template>
    <slot />
</template>

<script setup>
const { push } = useTagmanager();

onMounted(() => {
    window.unibuddySettings = {
        onUnibuddyEvent: handleUnibuddyEvent2
    };

    window.addEventListener('unibuddySettings.onUnibuddyEvent', handleUnibuddyEvent);
});

onUnmounted(() => {
    window.removeEventListener('unibuddySettings.onUnibuddyEvent', handleUnibuddyEvent);
});

const handleUnibuddyEvent2 = () => {};
const handleUnibuddyEvent = (event) => {
    if (event.name === 'prospectSignupComplete') {
        push({
            event: 'unibuddy.signup',
            data: event.data.email
        });
    }
};

useHead({
    script: [
        {
            hid: 'unibuddy-settings',
            src: '/assets/js/unibuddy-settings.js',
            body: true
        },
        {
            hid: 'chat-bubble',
            src: 'https://traffic-drivers.unibuddy.co/chat-bubble/embed.js',
            body: true,
            type: 'text/javascript',
            async: true,
            defer: true
        }
    ],
    bodyAttrs: {
        class: 'unibuddy-show'
    }
});
</script>

<style lang="css">
#unibuddy-chat-bubble-iframe {
    display: none;
}

.unibuddy-show #unibuddy-chat-bubble-iframe {
    display: block;
}
</style>
